export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Fast Search',
        to: '/userdetails',
        icon: 'cil-user',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Generic DB Access',
      //   to: '/generictables',
      //   icon: 'cil-user',
      //   badge: {
      //     color: 'secondary',
      //     text: 'NEW'
      //   }
      // },
      {
        _name: 'CSidebarNavTitle',
        _children: ['General']
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Tickets',
      //   route: '/ticketsboard',
      //   to: '/ticketsboard',
      //   icon: 'cil-puzzle'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Settings',
        to: '/adminsettings',
        icon: 'cil-puzzle'
      },

      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Announcements',
      //   to: '/',
      //   icon: 'cil-user'
      // },
        // Database
      {
        _name: 'CSidebarNavTitle',
        _children: ['Database']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Users',
        to: '/users',
        icon: 'cil-user',
        items: [
          {
            name: 'List Users',
            to: '/users'
          },
          {
            name: 'Migrate User',
            to: '/migrateuser'
          },
          {
            name: 'User Activity',
            to: '/userlogs'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Wlans',
        route: '/wlans',
        to: '/wlans',
        icon: 'cil-puzzle',
        items: [
          {
            name:'List Wlans',
            to: '/wlans'
          },
          {
            name: 'Create Wlan',
            to: '/createwlan'
          },
          {
            name: 'Emeergency Wlan',
            to: '/emergencywlan'
          }
          ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'AccessPoints',
        route: '/accesspoints',
        to: '/accesspoints',
        icon: 'cil-puzzle'
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Wireless Networks',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Wireless Groups',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Wireless Clients',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      //
      // // Switches Management
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['ICX Management']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Switches',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Switch Ports',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'LDAP',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // // FortiGate Management
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['FortiGate Management']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Firewalls',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'DHCP Pool',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'URL Filtering',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'VLANs',
      //   route: '/',
      //   icon: 'cil-puzzle'
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Buttons',
      //   route: '/buttons',
      //   icon: 'cil-cursor',
      //   items: [
      //     {
      //       name: 'Buttons',
      //       to: '/buttons/standard-buttons'
      //     },
      //     {
      //       name: 'Button Dropdowns',
      //       to: '/buttons/dropdowns'
      //     },
      //     {
      //       name: 'Button Groups',
      //       to: '/buttons/button-groups'
      //     },
      //     {
      //       name: 'Brand Buttons',
      //       to: '/buttons/brand-buttons'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Charts',
      //   to: '/charts',
      //   icon: 'cil-chart-pie'
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Icons',
      //   route: '/icons',
      //   icon: 'cil-star',
      //   items: [
      //     {
      //       name: 'CoreUI Icons',
      //       to: '/icons/coreui-icons',
      //       badge: {
      //         color: 'info',
      //         text: 'NEW'
      //       }
      //     },
      //     {
      //       name: 'Brands',
      //       to: '/icons/brands'
      //     },
      //     {
      //       name: 'Flags',
      //       to: '/icons/flags'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Notifications',
      //   route: '/notifications',
      //   icon: 'cil-bell',
      //   items: [
      //     {
      //       name: 'Alerts',
      //       to: '/notifications/alerts'
      //     },
      //     {
      //       name: 'Badges',
      //       to: '/notifications/badges'
      //     },
      //     {
      //       name: 'Modals',
      //       to: '/notifications/modals'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Widgets',
      //   to: '/widgets',
      //   icon: 'cil-calculator',
      //   badge: {
      //     color: 'primary',
      //     text: 'NEW',
      //     shape: 'pill'
      //   }
      // },
      // {
      //   _name: 'CSidebarNavDivider',
      //   _class: 'm-2'
      // },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Extras']
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Pages',
      //   route: '/pages',
      //   icon: 'cil-star',
      //   items: [
      //     {
      //       name: 'Login',
      //       to: '/pages/login'
      //     },
      //     {
      //       name: 'Register',
      //       to: '/pages/register'
      //     },
      //     {
      //       name: 'Error 404',
      //       to: '/pages/404'
      //     },
      //     {
      //       name: 'Error 500',
      //       to: '/pages/500'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Download CoreUI',
      //   href: 'http://coreui.io/vue/',
      //   icon: { name: 'cil-cloud-download', class: 'text-white' },
      //   _class: 'bg-success text-white',
      //   target: '_blank'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Try CoreUI PRO',
      //   href: 'http://coreui.io/pro/vue/',
      //   icon: { name: 'cil-layers', class: 'text-white' },
      //   _class: 'bg-danger text-white',
      //   target: '_blank'
      // }
    ]
  }
]