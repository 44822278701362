<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://aerwave.io" target="_blank">aerwave.io</a>
      <span class="ml-1"> {{new Date().getFullYear()}}  </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
